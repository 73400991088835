import React, { useContext } from "react";
import { assets } from "../../assets/assets";
import { isMobileContext } from "../../context/IsMobileContext";
import { LeftLogo } from "../NavBar";
import { motion } from "framer-motion";
import { mobileNavAnimation } from "../../animation";
import { IoCloseOutline } from "react-icons/io5";
import { navBar } from "../../constant";

function MobileNavPanel() {
  const { openMobileNav } = useContext(isMobileContext);

  return (
    <motion.div
      className="w-screen h-screen bg-black fixed z-[100] p-4 px-6 md:py-10 font-lato text-white"
      variants={mobileNavAnimation}
      initial="initial"
      exit="exit"
      animate={openMobileNav ? "open" : "initial"}
    >
      <TopSection />
      <BodySection />
    </motion.div>
  );
}

const TopSection = () => {
  const { setOpenMobileNav } = useContext(isMobileContext);
  return (
    <div className="flex justify-between items-center">
      <LeftLogo />
      <IoCloseOutline
        className="cursor-pointer text-[22px]"
        onClick={() => setOpenMobileNav(false)}
      />
    </div>
  );
};

const BodySection = () => {
  const { setOpenMobileNav } = useContext(isMobileContext);

  return (
    <div className="pl-4 sm:pl-8 w-full h-[80vh] flex flex-col justify-between">
      <div className="mt-4">
        {navBar.map((item) => {
          return (
            <div key={`mobile_${item.id}`}>
              <a
                className="transition-all text-[32px] duration-300 font-bold group flex itemscenter mb-4"
                href={item.url}
                onClick={() => setOpenMobileNav(false)}
              >
                <img
                  className="w-0 mr-0 transition-all duration-300 ease-in-out group-hover:w-8 group-hover:mr-1"
                  src={assets.starIcon}
                  alt="navigation-star-icon"
                />
                {item.name}
              </a>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MobileNavPanel;
