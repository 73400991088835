import { motion, useInView } from "framer-motion";
import React, { useRef } from "react";
import { textAnimation } from "../../animation";
import { assets } from "../../assets/assets";

function NewExperience() {
  return (
    <div
      className="relative mx-auto flex h-full max-w-[1200px] flex-col bg-oriBackground px-4 pt-14 md:justify-between lg:flex-row xl:h-[800px]"
      id="experience"
    >
      <div className="flex flex-col justify-between lg:pb-24">
        <Title />
        <JobInfo />
      </div>
      <Images />
    </div>
  );
}

const Title = () => {
  return (
    <h3 className="mb-8 text-heroMobileTitleResponsive font-bold">
      Experience{" "}
      <span className="font-playfairDisplay text-[24px] italic text-grayFont">
        (1)
      </span>
    </h3>
  );
};

const JobInfo = () => {
  const heading = useRef(null);
  const heading2 = useRef(null);
  const heading3 = useRef(null);
  const isHeadingInView = useInView(heading, { margin: "0px 0px -50px 0px" });
  const isHeadingInView2 = useInView(heading2, {
    margin: "0px 0px -50px 0px",
  });
  const isHeadingInView3 = useInView(heading3, {
    margin: "0px 0px -50px 0px",
  });

  const jobHeading = "Web";
  const jobHeading2 = "Development";
  const jobHeading3 = "Intern";

  return (
    <div>
      <p ref={heading}>
        {jobHeading.split("").map((item, index) => {
          return (
            <span
              key={item + index + `p1`}
              className="inline-flex overflow-hidden "
            >
              <motion.span
                className="text-heroMobileTitleResponsive font-bold leading-none"
                variants={textAnimation}
                custom={index}
                animate={isHeadingInView ? "open" : "initial"}
                initial="initial"
              >
                {item}
              </motion.span>
            </span>
          );
        })}
      </p>
      <p ref={heading2}>
        {jobHeading2.split("").map((item, index) => {
          return (
            <span
              key={item + index + `p2`}
              className="inline-flex overflow-hidden "
            >
              <motion.span
                className="text-heroMobileTitleResponsive font-bold leading-none"
                variants={textAnimation}
                custom={index}
                animate={isHeadingInView2 ? "open" : "initial"}
                initial="initial"
              >
                {item}
              </motion.span>
            </span>
          );
        })}
      </p>
      <p ref={heading3}>
        {jobHeading3.split("").map((item, index) => {
          return (
            <span
              key={item + index + `p3`}
              className="inline-flex overflow-hidden "
            >
              <motion.span
                className="text-heroMobileTitleResponsive font-bold leading-none"
                variants={textAnimation}
                custom={index}
                animate={isHeadingInView3 ? "open" : "initial"}
                initial="initial"
              >
                {item}
              </motion.span>
            </span>
          );
        })}
      </p>
      <h3 className="text-[24px] text-grayFont">
        iFAST Global Hub Ai Sdn. Bhd.
      </h3>
      <div className="mt-1 flex items-center gap-3 md:mt-2">
        <div className="h-[1px] w-[13%] bg-black"></div>
        <p className="text-[18px]">2023/10/16 - 2024/04/16</p>
      </div>
    </div>
  );
};

const Images = () => {
  const ifastPic = [
    {
      id: "ifastPic3",
      img: assets.ifast3,
      style: {
        height: "30%",
        position: "absolute",
        top: "5%",
        left: "5%",
        rotate: "-3.5deg",
        zIndex: "20",
      },
    },
    {
      id: "ifastPic2",
      img: assets.ifast2,
      style: {
        position: "absolute",
        top: "30%",
        right: "5%",
        rotate: "1.5deg",
        zIndex: "30",
      },
    },
    {
      id: "ifastPic1",
      img: assets.ifast1,
      style: {
        height: "25%",
        position: "absolute",
        top: "60%",
        left: "5%",
        rotate: "-1deg",
        zIndex: "35",
      },
    },
  ];
  return (
    <div className="relative mx-auto h-[600px] w-full max-w-[450px] sm:max-w-[500px] md:h-[700px] md:max-w-[550px] lg:w-[480px] xl:absolute xl:right-0 xl:top-0 xl:h-[750px] xl:w-[90%]">
      {ifastPic.map((item) => {
        return (
          <img
            key={item.id}
            src={item.img}
            className="w-[80%] max-w-[300px] rounded-[15px] object-cover sm:max-h-[250px] sm:min-h-[200px] sm:max-w-[380px] md:min-h-[220px] md:max-w-[400px] xl:min-h-[260px] xl:max-w-[450px]"
            style={item.style}
            alt="intern experience memory"
          />
        );
      })}
    </div>
  );
};

export default NewExperience;
