import React, { useRef } from "react";
import { motion, useScroll, useTransform } from "framer-motion";
import { assets } from "../assets/assets";
import { footerContact } from "../constant";

function Footer() {
  const container = useRef();
  const { scrollYProgress } = useScroll({
    target: container,
    offset: ["start end", "end start"],
  });

  return (
    <div className="h-full w-screen overflow-x-hidden bg-[#151515] pb-[48px] pt-[12vh] md:pb-[100px] md:pt-[20vh]">
      <div ref={container}>
        <Slider
          key={"Slider-1"}
          progress={scrollYProgress}
          direction={"right"}
          left={"-95%"}
        />
        <Slider
          key={"Slider-2"}
          progress={scrollYProgress}
          direction={"left"}
          left={"-38%"}
        />
        <Slider
          key={"Slider-3"}
          progress={scrollYProgress}
          direction={"right"}
          left={"-48%"}
        />
      </div>
      <div className="mt-12 flex flex-col gap-40 px-4 md:mt-20 md:px-8 lg:px-16">
        <Contact />
        <Credit />
      </div>
    </div>
  );
}

const Slider = ({ left, direction, progress }) => {
  const d = direction === "left" ? -1 : 1;
  const translateX = useTransform(progress, [0, 1], [50 * d, -50 * d]);

  return (
    <motion.div
      style={{ left: left, x: translateX }}
      className="relative flex whitespace-nowrap"
    >
      <Word />
      <Word />
      <Word />
      <Word />
    </motion.div>
  );
};

const Word = () => {
  return (
    <div>
      <p className="text-responsive font-bold leading-responsive text-white">
        Let's Work Together<span className="mx-4">·</span>{" "}
      </p>
    </div>
  );
};

const Contact = () => {
  return (
    <div className="flex flex-col gap-4 text-[20px] text-[#BDBDBD] md:gap-8 md:px-6 md:text-[24px]">
      {footerContact.map((item) => {
        return (
          <a
            key={item.id}
            className="group flex items-center transition-all duration-300 hover:text-white"
            href={item.url}
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={assets.starIcon}
              className="mr-0 w-0 transition-all duration-300 ease-in-out  group-hover:mr-2 group-hover:w-6 md:group-hover:w-8"
              alt="navigation-star-icon"
            />
            {item.name}
          </a>
        );
      })}
    </div>
  );
};

const Credit = () => {
  return (
    <div className="flex justify-between text-[24px] font-bold text-white">
      <p>Chee Siang</p>
      <p>2024</p>
    </div>
  );
};

export default Footer;
