import React, { useRef } from "react";
import { assets } from "../../assets/assets";
import { motion, useInView, useScroll, useTransform } from "framer-motion";
import { textAnimation } from "../../animation";
import { info } from "../../constant";

function NewAboutMe() {
  const imageContainer = useRef(null);
  const { scrollYProgress } = useScroll({
    target: imageContainer,
    offset: ["start 0.6", "end 0.2"],
  });

  const y = useTransform(scrollYProgress, [0, 1], [10, -40]);
  const sm = useTransform(scrollYProgress, [0, 1], [20, -170]);
  const md = useTransform(scrollYProgress, [0, 1], [80, -430]);
  const lg = useTransform(scrollYProgress, [0, 1], [120, -320]);
  const smRotation = useTransform(scrollYProgress, [0, 1], [4, -8]);
  const mdRotation = useTransform(scrollYProgress, [0, 1], [0, -4]);
  const lgRotation = useTransform(scrollYProgress, [0, 1], [1, 8]);

  const propic = [
    {
      id: "propic1",
      img: assets.propic,
      top: "20%",
      left: "15%",
      z: "20",
      rotate: smRotation,
      speed: sm,
    },
    {
      id: "propic2",
      img: assets.propic3,
      top: "45%",
      left: "45%",
      z: "20",
      rotate: lgRotation,
      speed: lg,
    },
    {
      id: "propic3",
      img: assets.propic2,
      top: "78%",
      left: "10%",
      z: "20",
      rotate: mdRotation,
      speed: md,
    },
  ];

  return (
    <div className="h-full flex flex-col items-center" id="about">
      <Title />
      <SelfInfo />

      <div
        ref={imageContainer}
        className="relative h-[600px] w-full max-w-[480px]"
      >
        {propic.map((item) => {
          return (
            <ProfilePicture
              key={item.id}
              speed={item.speed}
              img={item.img}
              top={item.top}
              left={item.left}
              z={item.z}
              rotate={item.rotate}
              y={y}
            />
          );
        })}
      </div>
    </div>
  );
}

const Title = () => {
  const titleTarget = useRef(null);
  const isTitleInView = useInView(titleTarget);
  const about = "(About) Me";
  return (
    <h3
      ref={titleTarget}
      className="mt-8 text-center text-heroMobileTitleResponsive inline-flex overflow-hidden"
    >
      {about.split("").map((item, index) => {
        return (
          <motion.span
            key={"About" + item + index}
            variants={textAnimation}
            initial="initial"
            custom={index}
            animate={isTitleInView ? "open" : "initial"}
            className={`${
              index >= about.length - 2
                ? "text-black font-bold"
                : "text-grayFont font-playfairDisplay italic"
            }`}
          >
            {item === " " ? "\u00A0" : item}
          </motion.span>
        );
      })}
    </h3>
  );
};

const SelfInfo = () => {
  return (
    <div className="pt-8">
      <div className="md:w-[65vw] flex flex-col justify-center items-center">
        {info.map((item) => {
          return (
            <div key={item.id} className="flex items-center gap-2 md:gap-4">
              <h4 className="text-[4vw] sm:text-[28px] lg:text-[36px] xl:text-[42px] font-playfairDisplay italic text-grayFont ">
                ({item.title})
              </h4>
              <p className="text-[3.5vw] sm:text-[18px] lg:text-[22px] xl:text-[28px]">
                {item.info}
                {item.supercase && (
                  <span className="align-super text-[2.5vw] sm:text-[14px] xl:text-[18px]">
                    {item.supercase}
                  </span>
                )}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const ProfilePicture = ({ speed, img, top, left, z, rotate, y }) => {
  return (
    <motion.div
      className="absolute z-50 top-0 left-0 w-[190px] h-[240px] rounded-[15px] overflow-hidden"
      style={{
        top: top,
        left: left,
        zIndex: z,
        rotate: rotate,
        y: speed,
      }}
    >
      <motion.img
        className="h-[290px] w-full object-cover"
        style={{ translateY: y }}
        src={img}
        alt="profile picture"
      />
    </motion.div>
  );
};

export default NewAboutMe;
