export const textAnimation = {
  initial: {
    y: "100%",
  },
  open: (index) => ({
    y: "0%",
    transition: { duration: 0.5, delay: 0.03 * index },
  }),
};

export const mobileNavAnimation = {
  initial: {
    transform: "translateX(100%)",
  },
  open: {
    transform: "translateX(0%)",
    transition: { duration: 0.75, type: "tween", ease: [0.85, 0, 0.2, 1] },
  },
  exit: {
    transform: "translateX(100%)",
    transition: { duration: 0.75, type: "tween", ease: [0.85, 0, 0.2, 1] },
  },
};

export const bgColorAnimation = {
  initial: {
    backgroundColor: "#fcf7ed",
  },
  after: {
    backgroundColor: "#e6e6e6",
  },
};
