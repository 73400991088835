import React, { useRef } from "react";
import Lottie from "lottie-react";
import ScrollAnimationIcon from "../icons/menu-scroll.json";
import { motion, useInView, useScroll, useTransform } from "framer-motion";
import { textAnimation } from "../animation";
import { assets } from "../assets/assets";

function Hero() {
  const container = useRef(null);
  const { scrollYProgress } = useScroll({
    target: container,
    offset: ["start start", "end start"],
  });

  const scale = useTransform(scrollYProgress, [0, 1], [0, -500]);

  return (
    <div
      ref={container}
      className="h-screen w-screen max-w-[1200px] mx-auto flex flex-col md:items-center justify-center gap-8 md:gap-10 py-24 px-4"
    >
      <MobileHeroSection scale={scale} />
      <ScrollInstruction />
    </div>
  );
}

const MobileHeroSection = ({ scale }) => {
  const p1 = useRef(null);
  const p2 = useRef(null);
  const p3 = useRef(null);
  const p4 = useRef(null);
  const isInView1 = useInView(p1);
  const isInView2 = useInView(p2);
  const isInView3 = useInView(p3);
  const isInView4 = useInView(p4);
  const paragraph1 = "Hey";
  const paragraph2 = "I'm Chee Siang";
  const paragraph3 = "a fresh graduate";
  const paragraph4 = "web developer";
  return (
    <div className="font-bold text-heroMobileTitleResponsive leading-heroMobileLeadingResponsive text-center">
      <div ref={p1} className="flex gap-4 justify-center items-center">
        <div>
          {paragraph1.split("").map((word, index) => {
            return (
              <p
                key={word + index + "0"}
                className="text-[#A6A6AE] inline-flex overflow-hidden"
              >
                <motion.span
                  variants={textAnimation}
                  initial="initial"
                  custom={index}
                  animate={isInView1 ? "open" : "initial"}
                >
                  {word}
                </motion.span>
              </p>
            );
          })}
        </div>
        <div className="w-[60vw] sm:w-[350px] rounded-full h-heroMobileImgHeight overflow-hidden">
          <motion.img
            style={{ width: "500px", maxWidth: "none", translateX: scale }}
            src={assets.heroGradient}
            alt="hero gradient image"
          />
        </div>
      </div>
      <div>
        <p ref={p2} className="inline-flex overflow-hidden">
          {paragraph2.split("").map((word, index) => {
            return (
              <motion.span
                key={word + index + "1"}
                variants={textAnimation}
                initial="initial"
                custom={index}
                animate={isInView2 ? "open" : "initial"}
                className={index < 3 ? "text-[#A6A6AE]" : ""}
              >
                {word === " " ? "\u00A0" : word}
              </motion.span>
            );
          })}
        </p>
      </div>

      <div>
        <p ref={p3} className="inline-flex overflow-hidden">
          {paragraph3.split("").map((word, index) => {
            return (
              <motion.span
                key={word + index + "2"}
                variants={textAnimation}
                initial="initial"
                animate={isInView3 ? "open" : "initial"}
                custom={index}
                className={index < 1 ? "text-[#A6A6AE]" : ""}
              >
                {word === " " ? "\u00A0" : word}
              </motion.span>
            );
          })}
        </p>
      </div>
      <div>
        <p ref={p4} className="inline-flex overflow-hidden">
          {paragraph4.split("").map((word, index) => {
            return (
              <motion.span
                key={word + index + "3"}
                variants={textAnimation}
                initial="initial"
                custom={index}
                animate={isInView4 ? "open" : "initial"}
              >
                {word === " " ? "\u00A0" : word}
              </motion.span>
            );
          })}
        </p>
      </div>
    </div>
  );
};

const ScrollInstruction = () => {
  return (
    <div className="flex flex-col items-center justify-center">
      <div className="w-[32px] sm:w-[42px] md:w-[48px]">
        <Lottie animationData={ScrollAnimationIcon} />
      </div>
      <p>Scroll to View More</p>
    </div>
  );
};

export default Hero;
