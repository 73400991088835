import React from "react";
import AchievementCarousel from "./AchievementCarousel";

const Achievement = () => {
  return (
    <div className="h-full min-h-screen space-y-4 p-4" id="achivement">
      <h3 className="text-center text-heroMobileTitleResponsive font-bold">
        Achievements
      </h3>

      <AchievementCarousel />
    </div>
  );
};

export default Achievement;
