import { assets } from "./assets/assets";

export const navBar = [
  { id: "nav_aboutme", name: "About Me", url: "#about" },
  { id: "nav_achievement", name: "Achivements", url: "#achivement" },
  { id: "nav_skill", name: "Skills", url: "#skill" },
  { id: "nav_experience", name: "Experience", url: "#experience" },
  { id: "nav_project", name: "Projects", url: "#project" },
];

export const footerContact = [
  { id: "footer_email", name: "Email", url: "mailto:ngcheesiang00@gmail.com" },
  {
    id: "footer_linkedIn",
    name: "LinkedIn",
    url: "https://www.linkedin.com/in/ng-chee-siang-527a98235/",
  },
];

export const selectedWorkInfo = [
  {
    sequence: 1,
    id: "selected_01",
    title: "FSMOne Government Bonds & T-Bills Landing Page",
    year: "2023",
    videoUrl: assets.govBondVid,
    url: "https://secure.fundsupermart.com/fsmone/government-bonds-landing-page",
    typeOfProject: "Internship Project",
    scale: "Fullstack",
    description: [
      "CI/CD",
      "Transform mockup into reality",
      "REST API",
      "Chart visualisation",
      "JSON data sorting & filtering",
      "Responsive design",
    ],
    skillsUsed: [
      "Angular",
      "TypeScript",
      "Tailwind CSS",
      "NG-ZORRO",
      "LESS Stylesheet",
      "Highcharts",
      "Spring MVC",
      "Hibernate",
      "Oracle SQL Developer",
      "Jenkins",
      "Jira",
      "PuTTY",
    ],
  },
  {
    sequence: 2,
    id: "selected_02",
    title: "FSMOne ETFestival 2024",
    year: "2024",
    videoUrl: assets.etfVid,
    url: "https://event.fsmone.com/event/microsite/etfest2024",
    typeOfProject: "Internship Project",
    scale: "Frontend",
    description: [
      "CI/CD",
      "Transform mockup into reality",
      "Responsive design",
    ],
    skillsUsed: ["CSS", "Vanilla JavaScript", "WinSCP", "PuTTY"],
  },
  {
    sequence: 3,
    id: "selected_03",
    title: "Tracker Apps",
    year: "2024",
    videoUrl: assets.trackerVid,
    url: "https://tracker.csland.me/",
    typeOfProject: "Side Project",
    scale: "Frontend",
    description: ["Responsive design", "Data sorting and filtering"],
    skillsUsed: ["React", "Tailwind CSS"],
  },
  {
    sequence: 4,
    id: "selected_04",
    title: "Recipe Website",
    year: "2024",
    videoUrl: assets.recipeVid,
    url: "https://recipe-fav.csland.me/",
    typeOfProject: "Side Project",
    scale: "Fullstack",
    description: [
      "REST API",
      "Data Caching",
      "Admin CRUD",
      "Authentication",
      "Authorization",
      "Responsive design",
    ],
    skillsUsed: [
      "React",
      "TypeScript",
      "Tailwind CSS",
      "Radix UI",
      "React Query",
      "React Hook Form",
      "Node",
      "Express",
      "MySQL",
      "JWT",
      "Zod",
    ],
  },
  {
    sequence: 5,
    id: "selected_05",
    title: "Cybersecurity Game-Based Learning",
    year: "2023",
    videoUrl: assets.fypVid,
    url: "",
    typeOfProject: "Final Year Project",
    scale: "Fullstack",
    description: [
      "Multiple user role",
      "3D modelling",
      "Dashboard",
      "Chart visualisation",
    ],
    skillsUsed: [
      "Vanilla CSS",
      "Vanilla JavaScript",
      "PHP",
      "MySQL",
      "Chart.js",
      "Spline",
    ],
  },
];

export const info = [
  {
    id: "info_01",
    title: "Name",
    info: "Ng Chee Siang",
    supercase: "(just call me CS)",
  },
  { id: "info_02", title: "Birthday", info: "2000 March 4" },
  { id: "info_03", title: "Uni", info: "Universiti Utara Malaysia" },
  { id: "info_04", title: "Course", info: "Information Technology" },
  { id: "info_05", title: "CGPA", info: "3.94 / 4.00" },
  {
    id: "info_06",
    title: "Fun Fact",
    info: "I love capybara & stuffed animals",
  },
];

export const skillSet = [
  { name: "Angular", rate: 4 },
  { name: "React", rate: 4 },
  { name: "Tailwind CSS", rate: 5 },
  { name: "Node.js", rate: 4 },
  { name: "Express.js", rate: 4 },
  { name: "Java", rate: 3 },
  { name: "Spring MVC", rate: 3 },
  { name: "MySQL", rate: 4 },
  { name: "Figma", rate: 4 },
  { name: "Jira", rate: 3 },
  { name: "Jenkins", rate: 3 },
];

export const achievementList = [
  {
    id: "achivement-01",
    year: "2023",
    prize: "Gold Award",
    title: "I-RIA 2023 Final Year Project",
    certUrl: assets.iriaCert,
    imgUrl: assets.iriaProj,
  },
  {
    id: "achivement-02",
    year: "2023",
    prize: "First Prize",
    title: "Artistic Design: Hackathon Series GDSC UUM",
    certUrl: assets.gdscCert,
    imgUrl: assets.gdscProj,
  },
];
