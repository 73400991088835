import React, { useContext, useRef } from "react";
import { motion, useTransform, useScroll } from "framer-motion";
import NewSkillsTitle from "./NewSkillsTitle";
import NewSkillsList from "./NewSkillsList";
import { isMobileContext } from "../../context/IsMobileContext";
function NewSkills() {
  const paddingAnimationTarget = useRef(null);
  const { scrollYProgress } = useScroll({
    target: paddingAnimationTarget,
    offset: ["end 0.9", "end 0.4"],
  });

  const mobilePadding = useTransform(scrollYProgress, [0, 1], [0, 16]);
  const desktpoPadding = useTransform(scrollYProgress, [0, 1], [0, 24]);
  const { isDesktop } = useContext(isMobileContext);
  return (
    <motion.div
      id="skill"
      className="pb-10"
      ref={paddingAnimationTarget}
      style={{
        padding: isDesktop ? desktpoPadding : mobilePadding,
      }}
    >
      <NewSkillsTitle />
      <NewSkillsList />
    </motion.div>
  );
}

export default NewSkills;
