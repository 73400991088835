import React, { useRef } from "react";
import { useInView } from "framer-motion";
import { assets } from "../../assets/assets";
import { skillSet } from "../../constant";

function NewSkillsList() {
  return (
    <div className="bg-black text-white rounded-b-[16px] pb-20">
      <div className="max-w-[350px] md:max-w-[400px] mx-auto">
        {skillSet.map((item) => {
          return (
            <Item key={`list_${item.name}`} name={item.name} rate={item.rate} />
          );
        })}
      </div>
    </div>
  );
}

const Item = ({ name, rate }) => {
  const skillTarget = useRef(null);
  const isInView = useInView(skillTarget, { margin: "-50% 0px -50% 0px" });
  return (
    <div
      ref={skillTarget}
      className={`flex items-center justify-between px-4 py-2 transition-all duration-150 ease-in-out
        ${
          isInView
            ? "opacity-1 font-bold text-[24px] md:text-[32px] "
            : "opacity-[0.45] text-[18px] md:text-[20px]"
        }`}
    >
      <div className="flex items-center gap-3">
        {isInView && <img src={assets.starIcon} className="w-8" alt="" />}
        <p>{name}</p>
      </div>
      <Rating keyName={name + rate} rate={rate} isInView={isInView} />
    </div>
  );
};

const Rating = ({ keyName, rate, isInView }) => {
  const generateRating = (rate) => {
    const empty = 5 - rate;
    let ratingDisplay = [];

    for (let i = 0; i < rate; i++) {
      ratingDisplay.push(
        <div
          key={"rating" + keyName + i}
          className={`${
            isInView ? "w-2 h-2" : "w-1.5 h-1.5"
          } rounded-full bg-white`}
        ></div>
      );
    }

    for (let i = 0; i < empty; i++) {
      ratingDisplay.push(
        <div
          key={"empty" + keyName + i}
          className={`${
            isInView ? "w-2 h-2" : "w-1.5 h-1.5"
          } rounded-full bg-slate-600`}
        ></div>
      );
    }
    return ratingDisplay;
  };
  return <div className="flex items-center gap-1">{generateRating(rate)}</div>;
};

export default NewSkillsList;
