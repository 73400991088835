import React, { useContext } from "react";
import { isMobileContext } from "../context/IsMobileContext";
import { assets } from "../assets/assets";
import { navBar } from "../constant";
import { CgMenuMotion } from "react-icons/cg";

function NavBar() {
  const { isDesktop } = useContext(isMobileContext);
  return (
    <nav className="fixed top-0 z-50 flex w-full max-w-[1200px] items-center justify-between bg-transparent p-4 px-6 text-white mix-blend-difference md:py-10 lg:items-start">
      <LeftLogo />
      {isDesktop ? <DesktopRightNav /> : <MobileRightNav />}
    </nav>
  );
}

export const LeftLogo = () => {
  return (
    <div className="flex justify-between ">
      <p className="text-[18px] font-bold">Chee Siang</p>
      <div className="bg-slate-400"></div>
    </div>
  );
};

const DesktopRightNav = () => {
  const navigateToSection = () => {
    document.querySelectorAll(".navLink").forEach((el) => {
      el.addEventListener("click", (e) => {
        e.preventDefault();
        const id = el.getAttribute("href")?.slice(1);
        if (!id) return;
        const target = document.getElementById(id);
        if (target) {
          target.scrollIntoView({ behavior: "smooth" });
        }
      });
    });
  };
  return (
    <div className="w-[240px] text-right text-[16px]">
      <ul>
        {navBar.map((item) => {
          return (
            <li
              key={`desktop_${item.id}`}
              className="group flex items-center justify-end gap-1"
              onClick={navigateToSection()}
            >
              <img
                className="w-0 transition-all duration-300 ease-in-out group-hover:w-5"
                src={assets.starIcon}
                alt="navigation-star-icon"
              />
              <a
                className="navLink transition-all duration-300 hover:text-[20px]"
                href={item.url}
              >
                {item.name}
              </a>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

const MobileRightNav = () => {
  const { setOpenMobileNav } = useContext(isMobileContext);

  return (
    <CgMenuMotion
      className="cursor-pointer text-[22px]"
      onClick={() => setOpenMobileNav(true)}
    />
  );
};

export default NavBar;
