import React, { useContext } from "react";
import { selectedWorkInfo } from "../../constant";
import { isMobileContext } from "../../context/IsMobileContext";
import { RxArrowTopRight } from "react-icons/rx";

function NewSelectedWorks() {
  const { isMobile } = useContext(isMobileContext);

  return (
    <div className="h-full w-full bg-selectedWork p-4" id="project">
      <div className="max-w-[1200px] mx-auto">
        <h3 className="text-heroMobileTitleResponsive font-bold">
          Projects
          <span className="text-[24px] font-playfairDisplay italic text-grayFont">
            ({selectedWorkInfo.length})
          </span>
        </h3>
        {isMobile ? (
          <MobileGrid selectedWorkInfo={selectedWorkInfo} />
        ) : (
          <MansoryGrid selectedWorkInfo={selectedWorkInfo} />
        )}
      </div>
    </div>
  );
}

const MobileGrid = ({ selectedWorkInfo }) => {
  return (
    <div
      className="grid grid-cols-1 md:grid-cols-2 gap-4"
      style={{ alignItems: "flex-start" }}
    >
      {selectedWorkInfo.map((item, index) => {
        return <Card key={item.id} index={index} {...item} />;
      })}
    </div>
  );
};

const MansoryGrid = ({ selectedWorkInfo }) => {
  return (
    <div className="flex gap-2 lg:gap-4">
      <div className="flex flex-col gap-2 lg:gap-4 flex-1">
        {selectedWorkInfo
          .filter((_, index) => index % 2 === 0)
          .map((item, index) => {
            return <Card key={item.id} index={index} {...item} />;
          })}
      </div>
      <div className="flex flex-col gap-2 lg:gap-4 flex-1">
        {selectedWorkInfo
          .filter((_, index) => index % 2 !== 0)
          .map((item, index) => {
            return <Card key={item.id} index={index} {...item} />;
          })}
      </div>
    </div>
  );
};

const Card = ({
  sequence,
  title,
  year,
  videoUrl,
  url,
  typeOfProject,
  scale,
  description,
  skillsUsed,
}) => {
  return (
    <div className="bg-selectedWorkCard rounded-[15px] w-full flex flex-col gap-4 p-4 md:p-6">
      <div className="flex justify-between items-center">
        <div className="flex gap-6 items-center">
          <p className="text-[14px] lg:text-[18px]">0{sequence}</p>
          <p className="text-[14px] lg:text-[18px] border-2 border-selectedWorkBorder px-3 py-1 rounded-full shadow-sm">
            {scale}
          </p>
          <p className="text-[14px] lg:text-[18px]">{typeOfProject}</p>
        </div>
        <p className="text-[14px] lg:text-[18px]">{year}</p>
      </div>

      <div className="relative">
        <video
          className="w-full aspect-square rounded-[12px]"
          src={videoUrl}
          autoPlay
          loop
          muted
        ></video>
        {url && (
          <a
            href={url}
            className="absolute bottom-[16px] right-[16px] z-20 bg-white rounded-full py-2 px-4 hover:shadow-xl transition-all duration-500 ease-in-out group flex items-center text-[16px] hover:text-[20px]"
            target="_blank"
            rel="noreferrer"
          >
            <RxArrowTopRight className="text-[20px] transition-all ease-in-out duration-300 group-hover:text-[28px] mr-1 pt-0.5" />
            Go to Website
          </a>
        )}
      </div>

      <h3 className="font-bold text-[24px] md:text-[32px] leading-tight">
        {title}
      </h3>

      <div className="flex flex-wrap w-full gap-2 lg:gap-4">
        {description.map((item) => {
          return (
            <p
              key={"skillsUsed" + title + item}
              className="bg-selectedWorkDescriptionTag text-[#FEFEFE] px-5 py-2 rounded-full inline text-[14px] lg:text-[18px]"
            >
              {item}
            </p>
          );
        })}
      </div>
      <div className="flex flex-wrap w-full gap-2 lg:gap-4">
        {skillsUsed.map((item) => {
          return (
            <p
              key={"skillsUsed" + title + item}
              className="bg-white px-5 py-2 rounded-full inline text-[14px] lg:text-[18px]"
            >
              {item}
            </p>
          );
        })}
      </div>
    </div>
  );
};

export default NewSelectedWorks;
