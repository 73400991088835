import mobileNavStarIcon from "./icons/mobileNavStarIcon.svg";
import starIcon from "./icons/starIcon.svg";
import heroGradient from "./pic/hero_gradient.avif";
import ifast1 from "./pic/ifast1.jpg";
import ifast2 from "./pic/ifast2.jpg";
import ifast3 from "./pic/ifast3.jpg";
import propic from "./pic/propic.png";
import propic2 from "./pic/propic2.jpg";
import propic3 from "./pic/propic3.jpg";
import etfVid from "./screenshot/etfest-vid.mp4";
import fypVid from "./screenshot/fyp-vid.mp4";
import govBondVid from "./screenshot/gov-bond-vid.mp4";
import trackerVid from "./screenshot/tracker-vid.mp4";
import recipeVid from "./screenshot/recipe-vid.mp4";
import iriaCert from "./achievement/iria.png";
import iriaProj from "./achievement/cak.png";
import gdscCert from "./achievement/gdsc_cert.png";
import gdscProj from "./achievement/tote-bag.jpg";

export const assets = {
  starIcon,
  mobileNavStarIcon,
  ifast1,
  ifast2,
  ifast3,
  propic,
  propic2,
  propic3,
  heroGradient,
  govBondVid,
  etfVid,
  fypVid,
  trackerVid,
  recipeVid,
  iriaCert,
  iriaProj,
  gdscCert,
  gdscProj,
};
