import React, { useRef } from "react";
import { motion, useTransform, useScroll } from "framer-motion";

function NewSkillsTitle() {
  const title = "My Skills";
  return (
    <div className="bg-black">
      <Title title={title} />
    </div>
  );
}

const Title = ({ title }) => {
  const horizontalScrollBox = useRef(null);
  const { scrollYProgress } = useScroll({
    target: horizontalScrollBox,
    offset: ["start 0.5", "start start"],
  });
  const x = useTransform(scrollYProgress, [0, 1], ["10%", "-55%"]);

  return (
    <div className="relative h-full" ref={horizontalScrollBox}>
      <div className="sticky top-[28vh] lg:top-[18vh] overflow-hidden">
        <motion.p
          className="lg:leading-snug relative font-bold text-white w-max text-[30vw] lg:text-[20vw] flex gap-8"
          style={{ x }}
        >
          {title.split(" ").map((item, index) => {
            return (
              <AnimatedText
                key={index + item}
                index={index}
                content={item}
                progress={scrollYProgress}
              />
            );
          })}
        </motion.p>
      </div>
    </div>
  );
};

const AnimatedText = ({ content, index, progress }) => {
  const start = index / 10;
  const end = start + 1 / 10;
  const opacity = useTransform(progress, [start, end], [0.35, 1]);

  return (
    <motion.span className="mr-[8vw]" style={{ opacity: opacity }}>
      {content}
    </motion.span>
  );
};

export default NewSkillsTitle;
