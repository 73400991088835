import React from "react";
import { VscArrowLeft, VscArrowRight } from "react-icons/vsc";

const AchivementItem = ({
  year,
  title,
  prize,
  imgUrl,
  certUrl,
  index,
  setIndex,
  currentIndex,
  totalLength,
}) => {
  return (
    <div
      className={`${
        currentIndex === index ? "z-10 opacity-100" : "z-0 opacity-10"
      } absolute left-0 top-0 w-full space-y-2 transition-all ease-in-out md:space-y-4`}
    >
      <div className="grid grid-cols-[auto_140px] md:grid-cols-[15%_auto_150px]">
        <div className="flex flex-row items-center gap-2 md:flex-col md:items-start md:gap-1">
          <span className="rounded-full bg-selectedWorkDescriptionTag px-3 py-2 text-sm text-white">
            {currentIndex + 1} / {totalLength}
          </span>
          <h3
            className={`${
              currentIndex === index
                ? "opacity-100 delay-150"
                : " translate-y-2 opacity-0"
            } pl-1 text-lg transition-all duration-300 ease-in-out`}
          >
            {year}
          </h3>
        </div>
        <div className="hidden md:block">
          <h3
            className={`${
              currentIndex === index
                ? "opacity-100 delay-200"
                : "translate-y-5 opacity-0"
            } text-[3.5vw] transition-all duration-300 ease-in-out sm:text-[18px] lg:text-[22px] xl:text-[26px]`}
          >
            {prize}
          </h3>
          <h3
            className={`${
              currentIndex === index
                ? "opacity-100 delay-300"
                : "translate-y-5 opacity-0"
            } text-[3.5vw] transition-all duration-300 ease-in-out sm:text-[24px] lg:text-[28px] xl:text-[32px]`}
          >
            {title}
          </h3>
        </div>
        <div className="flex items-start justify-center gap-4">
          <div
            onClick={() => {
              currentIndex !== 0
                ? setIndex(currentIndex - 1)
                : setIndex(totalLength - 1);
            }}
            className="flex h-14 w-14 cursor-pointer items-center justify-center rounded-full border-2"
          >
            <VscArrowLeft className="text-2xl text-grayFont" />
          </div>
          <div
            onClick={() => {
              currentIndex !== totalLength - 1
                ? setIndex(currentIndex + 1)
                : setIndex(0);
            }}
            className="flex h-14 w-14 cursor-pointer items-center justify-center rounded-full border-2"
          >
            <VscArrowRight className="text-2xl text-grayFont" />
          </div>
        </div>
      </div>

      {/* Mobile middle Grid */}
      <div className="block md:hidden">
        <h3
          className={`${
            currentIndex === index
              ? "opacity-100 delay-300"
              : "translate-y-5 opacity-0"
          } text-[3.5vw] transition-all duration-300 ease-in-out`}
        >
          {prize}
        </h3>
        <h3
          className={`${
            currentIndex === index
              ? "opacity-100 delay-500"
              : "translate-y-5 opacity-0"
          } text-[4.5vw] transition-all duration-300 ease-in-out`}
        >
          {title}{" "}
        </h3>
      </div>

      {/* Image Grid */}
      <div className="grid grid-cols-1 grid-rows-2 gap-4 md:grid-cols-2 md:grid-rows-1">
        <div className="overflow-hidden rounded-[15px]">
          <img
            src={certUrl}
            className={`${
              currentIndex === index
                ? "opacity-100 delay-300"
                : "translate-y-5 scale-105 opacity-0 "
            } aspect-square h-full w-full object-cover transition-all duration-300 ease-in-out`}
            alt={title}
            loading="lazy"
          />
        </div>
        <div className=" overflow-hidden rounded-[15px]">
          <img
            src={imgUrl}
            className={`${
              currentIndex === index
                ? "opacity-100 delay-500"
                : "translate-y-5 scale-105 opacity-0"
            }  aspect-square h-full w-full object-cover transition-all duration-300 ease-in-out`}
            alt={title}
            loading="lazy"
          />
        </div>
      </div>
    </div>
  );
};

export default AchivementItem;
