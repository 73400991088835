import React, { useState } from "react";

import { achievementList } from "../../constant";
import AchivementItem from "./AchivementItem";

const AchievementCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  return (
    <div className="mx-auto h-[220vw] max-w-[1200px] space-y-6 md:h-[60vw] xl:h-[750px]">
      <div className="relative flex flex-col gap-4">
        {achievementList.map((item, index) => (
          <AchivementItem
            key={item.id}
            year={item.year}
            title={item.title}
            prize={item.prize}
            imgUrl={item.imgUrl}
            certUrl={item.certUrl}
            index={index}
            currentIndex={currentIndex}
            setIndex={setCurrentIndex}
            totalLength={achievementList.length}
          />
        ))}
      </div>
    </div>
  );
};

export default AchievementCarousel;
