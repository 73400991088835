import React, { useContext, useEffect } from "react";
import { AnimatePresence } from "framer-motion";
import Lenis from "lenis";
import NavBar from "./components/NavBar";
import Hero from "./components/Hero";
import NewAboutMe from "./components/new-components/NewAboutMe";
import NewSkills from "./components/new-components/NewSkills";
import NewExperience from "./components/new-components/NewExperience";
import NewSelectedWorks from "./components/new-components/NewSelectedWorks";
import Footer from "./components/Footer";
import MobileNavPanel from "./components/new-components/MobileNavPanel";
import { isMobileContext } from "./context/IsMobileContext";
import Achievement from "./components/new-components/Achievement";

function App() {
  const { openMobileNav } = useContext(isMobileContext);
  useEffect(() => {
    const lenis = new Lenis();

    function raf(time) {
      lenis.raf(time);
      requestAnimationFrame(raf);
    }

    requestAnimationFrame(raf);
  }, []);

  return (
    <div>
      <AnimatePresence mode="wait">
        {openMobileNav && <MobileNavPanel />}
      </AnimatePresence>
      <div className="bg-oriBackground font-lato">
        <div className="max-w-[1200px] mx-auto">
          <NavBar />
        </div>
        <Hero />
        <NewAboutMe />
        <Achievement />
        <NewSkills />
        <NewExperience />
        <NewSelectedWorks />
        <Footer />
      </div>
    </div>
  );
}

export default App;
