import React, { createContext, useState, useEffect } from "react";

export const isMobileContext = createContext(null);

const IsMobileContextProvider = ({ children }) => {
  const [isMobile, setIsMobile] = useState(false);
  const [isDesktop, setIsDesktop] = useState(false);
  const [openMobileNav, setOpenMobileNav] = useState(false);

  useEffect(() => {
    const handleScreenSize = () => {
      const checkIsMobile = window.innerWidth < 768;
      const checkIsDesktop = window.innerWidth >= 1024;
      setIsMobile(checkIsMobile);
      setIsDesktop(checkIsDesktop);
      if (window.innerWidth >= 1024) {
        setOpenMobileNav(false);
      }
    };

    handleScreenSize();
    window.addEventListener("resize", handleScreenSize);

    return () => window.removeEventListener("resize", handleScreenSize);
  }, []);

  const value = { isMobile, isDesktop, openMobileNav, setOpenMobileNav };

  return (
    <isMobileContext.Provider value={value}>
      {children}
    </isMobileContext.Provider>
  );
};

export default IsMobileContextProvider;
